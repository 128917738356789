@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-x: hidden;
}

@layer components {
    @media only screen and (max-width: 992px) {
        .po_relative {
            position: relative;
        }
        .po_absolute {
            position: absolute;
            top: 69%;
            left: 0;
            width: 100%;
        }
        .flex-wrap {
            flex-wrap: nowrap;
        }
        .logo-img {
            width: auto; 
            max-width: 150px; 
        }        
    }
}
